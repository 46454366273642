
// export const environment_conf = {
//   environment: "UAT",
//   base_app_domain: "https://uatapp.spectaclesreader.com",
//   base_ai_domain:"https://uatai.spectaclesreader.com",
//   base_llm_domain: "https://2jqgmipw62.execute-api.us-east-1.amazonaws.com",
//   base_manifest_domain: "https://uatmanifest.spectaclesreader.com",
//   base_marketing_domain: "https://www.spectaclesreader.com",
//   okta_client_id: "00eef15c-514b-4427-ab9d-b695eacf7442",
//   isProduction: false,
//   useStub: false,
//  };

// export const environment_conf = {
//   environment: "Development",
//   base_app_domain: "https://localhost:4000",
//   base_llm_domain: "https://localhost",
//   base_ai_domain:"https://localhost:4000",
//   base_manifest_domain: "https://localhost:3000",
//   base_marketing_domain: "https://www.spectaclesreader.com",
//   okta_client_id: "00eef15c-514b-4427-ab9d-b695eacf7442",
//   isProduction: false,
//   useStub: true,
//  };

// export const environment_conf = {
//     environment: "Development",
//     base_app_domain: "https://uatapp.spectaclesreader.com",
//     base_llm_domain: "https://2jqgmipw62.execute-api.us-east-1.amazonaws.com",
//     // base_llm_domain: "http://127.0.0.1:4000/",
//     base_ai_domain:"https://localhost:4000",
//     base_manifest_domain: "https://localhost:3000",
//     base_marketing_domain: "https://www.spectaclesreader.com",
//     okta_client_id: "00eef15c-514b-4427-ab9d-b695eacf7442",
//     isProduction: false,
//     useStub: true,
//    };

/*
export const environment_conf = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  base_app_domain: process.env.REACT_APP_SPECTACLES_BASEAPPDOMAIN,
  base_manifest_domain: process.env.REACT_APP_SPECTACLES_BASEMANIFESTDOMAIN,
  base_marketing_domain: process.env.REACT_APP_SPECTACLES_BASEMARKETINGDOMAIN,
  okta_client_id: process.env.REACT_APP_OKTA_CLIENTID,
  isProduction: process.env.REACT_APP_ENVIRONMENT.toUpperCase() === 'PRODUCTION'
};
*/

/*
export const environment_conf = {
  environment: "Development",
  base_app_domain: "https://localhost:44355",
  base_ai_domain:"https://uatai.spectaclesreader.com",
  base_manifest_domain: "https://localhost:3000",
  base_marketing_domain: "https://www.spectaclesreader.com",
  okta_client_id: "00eef15c-514b-4427-ab9d-b695eacf7442",
  isProduction: false,
  useStub: false,
 };


export const environment_conf = {
  environment: "UAT",
  base_app_domain: "https://uatapp.spectaclesreader.com",
  base_ai_domain:"https://uatai.spectaclesreader.com",
  base_manifest_domain: "https://uatmanifest.spectaclesreader.com",
  base_marketing_domain: "https://www.spectaclesreader.com",
  okta_client_id: "00eef15c-514b-4427-ab9d-b695eacf7442",
  isProduction: false,
  useStub: false,
};
*/

// export const environment_conf = {
//   environment: "Development",
//   base_app_domain: "https://localhost",
//   // base_app_domain: "https://uatapp.spectaclesreader.com",
//   base_llm_domain: "https://2jqgmipw62.execute-api.us-east-1.amazonaws.com",
//   base_ai_domain:"https://ai.spectaclesreader.com",
//   base_manifest_domain: "https://localhost:3000",
//   base_marketing_domain: "https://www.spectaclesreader.com",
//   okta_client_id: "077c8e97-26cf-4101-b3e6-817f9ce4e7c3",
//   isProduction: false
// };

// export const environment_conf = {
//   environment: "UAT",
//   base_app_domain: "https://uatapp.spectaclesreader.com",
//   base_llm_domain: "https://2jqgmipw62.execute-api.us-east-1.amazonaws.com",
//   base_ai_domain:"https://ai.spectaclesreader.com",
//   base_manifest_domain: "https://uatmanifest.spectaclesreader.com/",
//   base_marketing_domain: "https://www.spectaclesreader.com",
//   okta_client_id: "077c8e97-26cf-4101-b3e6-817f9ce4e7c3",
//   isProduction: false
// };

// export const environment_conf = {
//   environment: "Production",
//   base_app_domain: "https://app.spectaclesreader.com",
//   base_llm_domain: "https://2jqgmipw62.execute-api.us-east-1.amazonaws.com",
//   base_ai_domain: "https://ai.spectaclesreader.com",
//   base_manifest_domain: "https://manifest.spectaclesreader.com/",
//   base_marketing_domain: "https://www.spectaclesreader.com",
//   okta_client_id: "077c8e97-26cf-4101-b3e6-817f9ce4e7c3",
//   isProduction: true,
// };


export const environment_conf = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  base_app_domain: process.env.REACT_APP_SPECTACLES_BASE_APP_DOMAIN,
  base_llm_domain: process.env.REACT_APP_SPECTACLES_BASE_LLM_DOMAIN,
  base_ai_domain: process.env.REACT_APP_SPECTACLES_BASE_AI_DOMAIN,
  base_manifest_domain: process.env.REACT_APP_SPECTACLES_BASE_MANIFEST_DOMAIN,
  base_marketing_domain: process.env.REACT_APP_SPECTACLES_BASE_MARKETING_DOMAIN,
  okta_client_id: process.env.REACT_APP_OKTA_CLIENT_ID,
  isProduction: process.env.REACT_APP_ENVIRONMENT.toUpperCase() === 'PRODUCTION'
};
