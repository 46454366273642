import styled from 'styled-components';


export const LinkButton = styled.button`
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
`;
