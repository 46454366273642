import {
  SET_LICENSE_TYPE,
  SET_USER,
  SET_ADMIN,
  SET_SUBSCRIPTION_OWNER,
  SET_ACCESS_TOKEN,
  SET_ANALYSIS_ENGINE,
  SET_AI_PROVIDER,
  SET_AI_MODEL,
  SET_AI_API_KEY,
  SET_AI_SETTINGS,
} from "../constants/actionTypes";
import { LICENSE_TYPES, User } from "../interfaces/IUserInterface";

export type setLicenseTypeType = (payload: LICENSE_TYPES) => void;
export const setLicenseType: setLicenseTypeType = (payload) => ({
  type: SET_LICENSE_TYPE,
  payload,
});

export type setUserType = (payload: User) => void;
export const setUser: setUserType = (payload) => ({
  type: SET_USER,
  payload,
});

export type setAdminType = (payload: boolean) => void;
export const setAdmin: setAdminType = (payload) => ({
  type: SET_ADMIN,
  payload,
});

export type setSubscriptionOwnerType = (payload: boolean) => void;
export const setSubscriptionOwner: setSubscriptionOwnerType = (payload) => ({
  type: SET_SUBSCRIPTION_OWNER,
  payload,
});

export type setAccessTokenType = (payload: boolean) => void;
export const setAccessToken: setAccessTokenType = (payload) => ({
  type: SET_ACCESS_TOKEN,
  payload,
});

export type setAnalysisEngineType = (payload: boolean) => void;
export const setAnalysisEngine: setAnalysisEngineType = (payload) => ({
  type: SET_ANALYSIS_ENGINE,
  payload,
});


export type setAiProviderType = (payload: string) => void;
export const setAiProvider: setAiProviderType = (payload) => ({
  type: SET_AI_PROVIDER,
  payload
});

export type setAiModelType = (payload: string) => void;
export const setAiModel: setAiModelType = (payload) => ({
  type: SET_AI_MODEL,
  payload
});

export type setAiApiKeyType = (payload: string) => void;
export const setAiApiKey: setAiApiKeyType = (payload) => ({
  type: SET_AI_API_KEY,
  payload
});

export type setAiSettingsType = (payload: {
  aiProvider: string;
  aiModel: string;
  apiKey: string;
}) => void;
export const setAiSettings: setAiSettingsType = (payload) => ({
  type: SET_AI_SETTINGS,
  payload
});
