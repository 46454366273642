import * as React from 'react';
import styled from 'styled-components';
import { ChangeEvent, useState } from 'react';
import { LinkButton } from './ui/LinkButton';

const prompts = [
    {
      propmt:"What does Section [#.#.(a)] mean?",
      guardrial:false,
      highlighted:false,
    },
    {
      propmt:"What is the exact text of Section [#.}?",
      guardrial:false,
      highlighted:false,
    },
    {
      propmt:"What does the [Term] mean?",
      guardrial:false,
      highlighted:false,
    },
    {
      propmt:"What is the exact definition of [Term]?",
      guardrial:false,
      highlighted:false,
    },
    {
      propmt:"Does Section [#.#] have any references to other sections? What are they?",
      guardrial:false,  
      highlighted:false,
    },
    {
      propmt:"Does the definition of [Term] have any references to other Sections? What are they?",
      guardrial:false,
      highlighted:false,
    },
    {
      propmt:"Is there an obligation of [Borrower/Lender] to do [insert task]? In what Section? Or “Where”?",
      guardrial:false,
      highlighted:false,
    },
    {
      propmt:"Can you summarize [Section or Term]?",
      guardrial:false,
      highlighted:false,
    },
    {
      propmt:"Can you summarize the highlighted text?",
      guardrial: false,
      highlighted:true,
    },
    {
      propmt:"What does the highlighted text mean?",
      guardrial:false,
      highlighted:true,
    },
    {
      propmt:"Can you make [insert request] change to the highlighted text?",
      guardrial: true,
      highlighted:true,
    },
    {
      propmt:"Can you suggest a clause with [insert specific request] features?",
      guardrial: true,
      highlighted:false,
    },
    {
      propmt:"Can you add [more specific feature] to the clause suggested?",
      guardrial: true,
      highlighted:false,
    },
    {
      propmt:"Can you conform this clause [feed it a clause] to fit this document?",
      guardrial:false,
      highlighted:false,
    },
    {
      propmt:"Can you conform this clause [feed it a clause] to have [insert specific request]?",
      guardrial:false,
      highlighted:false,
    },
    {
      propmt:"Can you tell me if there is a [insert] in this document?",
      guardrial: false,
      highlighted: false,
    },
    {
      propmt:"Can you give me the [purchase price/loan amount/price per share?",
      guardrial: false,
      highlighted: false,
    },
  {
    propmt:"What are the requirements of [insert Title / Article]? ",
    guardrial: false,
    highlighted: false,
  },
  ]


export type InputPrompt = {
  propmt: string;
  guardrial: boolean;
  highlighted: boolean;
}
  
interface Props {
    isLoading: boolean;
    aiPrompt: (prompt: InputPrompt) => void;
    clearChat: () => void;
    textareaRef: React.RefObject<HTMLTextAreaElement>;
}

const StyledTextarea = styled.textarea`
    width: 100%;
    resize: none;
    border: 1px solid grey;
    outline: none;
    overflow: auto;
    border-radius: 50px;
    padding: 1rem 2rem; 
`

const StyledButton = styled.button`
  position: absolute;
  border: none;
  background: none;
  right: 1rem;
  top: 56%;
  aspect-ratio: 1;
  cursor: pointer;
  transform: translateY(-50%);
`

const StyledWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
  flex-direction: column;
  padding-top: 5px;
  width: 100%;
`

const PromptList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  position: absolute;
  bottom: 65px;
  left: 0;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  z-index: 100000;
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  & > div {
    cursor: pointer;
    font-size: 13px;
    padding: 4px;
    &:hover {
      background-color: #f0f0f0;
    }
  }
`   


const PromptInput:React.FC<Props>  = ({isLoading, aiPrompt, clearChat, textareaRef}: Props) => {

    const [prompt, setPrompt] = useState<InputPrompt>({propmt: '', guardrial: false, highlighted: false});
    const [isPromptListOpen, setIsPromptListOpen] = useState<boolean>(false);

    function onEnter(event) {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevents the default action of inserting a newline
            aiPrompt(prompt);
            setPrompt({propmt: '', guardrial: false, highlighted: false})
          }
    }

    const autosizeTextarea = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = event.target;
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
      };
    
      function filterPrompt(prompt: InputPrompt) {
        setPrompt(prompt);
        setIsPromptListOpen(false);
      }

      const recommendedPrompts = prompts.filter((p) => p.propmt.toLowerCase().includes(prompt.propmt.toLowerCase().trim()));

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
       <StyledWrapper>
       <StyledTextarea
            ref={textareaRef}
            name="text"
            id="text"
            autoComplete="off"
            value={prompt.propmt}
            onChange={(e) => {
                setPrompt({ ...prompt, propmt: e.target.value,});
                setIsPromptListOpen(true);
            }}
            onInput={autosizeTextarea}
            onKeyDown={onEnter}
            rows={1}
            placeholder={isLoading ? "Thinking..." : "Ask Spectacles"}
        />
        <StyledButton
      id="revise"
      type="button"
      onClick={() => {
        console.log(prompt)
        aiPrompt(prompt)
        setPrompt({propmt: '', guardrial: false, highlighted: false})
      }}
        >
              <img
          width="24"
          height="24"
          src="/assets/spectacles-monocles.png"
          alt="Spectacles Monocles Logo"
        />
        </StyledButton>
          

     { (recommendedPrompts.length > 0 && prompt.propmt.length > 0 && isPromptListOpen) && (
          <PromptList>
          {recommendedPrompts.map((prompt, index) => (
            <div key={index} onClick={() => filterPrompt(prompt)}>
              {prompt.propmt}
            </div>
          ))}
         </PromptList>
     )}
       </StyledWrapper>
       <LinkButton onClick={() => clearChat()} style={{ textAlign: 'center' }}>
                 Clear Chat
               </LinkButton>
      </div>
    );
};

export default PromptInput;