import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../utilities/server';
import { RootState } from '../reducers';
import { User } from '../interfaces/IUserInterface';
import { SET_USER } from '../constants/actionTypes';

export const useUser = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user as User);

  useEffect(() => {
    const loadUser =  () => {
      try {
       getUser(user.name).then(data => {
          setUser({
            ...data,
            aiApiKey: data.apiKey
          });
        });

      } catch (error) {
        console.error('Error loading user:', error);
      }
    };

    if (user.name) {
      loadUser();
    }
  }, [user.name, dispatch]);

  function setUserProperty(key: keyof User,value: any){
    dispatch({
      type: SET_USER,
      payload: {
        ...user,
        [key]: value
      }
    });
  }

  function setUser(user: User) {
    dispatch({
      type: SET_USER,
      payload: user
    });
  }

  return {
    user,
    masterUserId: Number(user.masterUserId) === 0 ? user.id : user.masterUserId,
    setUser,
    setUserProperty
  };
};
