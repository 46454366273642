import * as React from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import { RouteComponentProps, Link } from "react-router-dom";
import {
  Settings,
  LogOut,
  HelpCircle,
  File,
  CloudLightning,
  Grid,
} from "react-feather";

import { environment_conf } from "../../../utilities/environment";

interface IHeaderProps extends RouteComponentProps<{}> {
  logout: () => {};
  handleGraphClick: () => {};
}

var addPadding = {
  padding: "10px 10px 5px 0",
};

var linkPadding = {
  padding: "7px 0 0 0",
};

const Header: React.StatelessComponent<IHeaderProps> = (props) => (
  <>
    <Container>
      <InnerContainer>
        <Link style={linkPadding} title="Home" to="/">
          <img width="50%" style={{maxWidth: '180px'}} src="/assets/logo-white.png" />
        </Link>

        <a style={addPadding} title="Graph" onClick={props.handleGraphClick}>
          <Grid />
        </a>

        <Link style={addPadding} title="Clio" to="/clio">
          <File />
        </Link>

        <Link style={addPadding} title="Settings" to="/settings">
          <Settings />
        </Link>

        <a
          style={addPadding}
          title="Support"
          href="https://spectaclesreader.com/support/"
          target="_blank"
        >
          <HelpCircle />
        </a>

        <a style={addPadding} title="Logout" onClick={props.logout}>
          <LogOut />
        </a>
      </InnerContainer>
    </Container>
  </>
);

const Container = styled.header`
  background-color: ${(props) => props.theme.pallet.primary};
  box-shadow: ${(props) => props.theme.ui.boxShadow};
  padding: 4px 15px 4px 15px;
  margin-bottom: 3px;
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;

  a:not(.header-ignore) {
    color: #fff;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
  }
`;

export default withRouter(Header);
