import {
  SET_USER,
  SET_LICENSE_TYPE,
  SET_ADMIN,
  SET_SUBSCRIPTION_OWNER,
  SET_ACCESS_TOKEN,
  SET_ANALYSIS_ENGINE,
  SET_AI_MODEL,
  SET_AI_API_KEY,
  SET_AI_PROVIDER,
} from "../constants/actionTypes";
import { LICENSE_TYPES } from "../interfaces/IUserInterface";

export const initialState = {
  id: "",
  name: "",
  email: "",
  loggedIn: false,
  licenseType: LICENSE_TYPES.NONE,
  admin: false,
  authStatus: "notLoggedIn",
  provider: "",
  accessToken: "",
  aiProvider: "",
  aiModel: "",
  aiOrganisationId: "",
  aiApiKey: "",
  masterUserId: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload,
      };
    case SET_LICENSE_TYPE: {
      return {
        ...state,
        licenseType: action.payload,
      };
    }
    case SET_ADMIN: {
      return {
        ...state,
        admin: action.payload,
      };
    }
    case SET_SUBSCRIPTION_OWNER: {
      return {
        ...state,
        subscriptionOwner: action.payload,
      };
    }
    case SET_ACCESS_TOKEN: {
      return {
        ...state,
        accessToken: action.payload,
      };
    }
    case SET_ANALYSIS_ENGINE: {
      return {
        ...state,
        analysisEngine: action.payload,
      };
    }
    case SET_AI_PROVIDER:
      return {
        ...state,
        aiProvider: action.payload,
      };
    case SET_AI_MODEL:
      return {
        ...state,
        aiModel: action.payload,
      };
    case SET_AI_API_KEY:
      return {
        ...state,
        aiApiKey: action.payload,
      };
    default:
      return state;
  }
}
