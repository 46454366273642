// Terms
export const SET_TERMS_START = "SET_TERMS_START";
export type SET_TERMS_START = typeof SET_TERMS_START;

export const SET_TERMS_SUCCESS = "SET_TERMS_SUCCESS";
export type SET_TERMS_SUCCESS = typeof SET_TERMS_SUCCESS;

export const SET_TERMS_ERROR = "SET_TERMS_ERROR";
export type SET_TERMS_ERROR = typeof SET_TERMS_ERROR;

export const SET_TERMS_SEARCHING_DEFINED = "SET_TERMS_SEARCHING_DEFINED";
export type SET_TERMS_SEARCHING_DEFINED = typeof SET_TERMS_SEARCHING_DEFINED;

export const SET_TERMS_MERGING = "SET_TERMS_MERGING";
export type SET_TERMS_MERGING = typeof SET_TERMS_MERGING;

export const SET_CURRENT_TERM_OFFSET_TOP = "SET_CURRENT_TERM_OFFSET_TOP";
export type SET_CURRENT_TERM_OFFSET_TOP = typeof SET_CURRENT_TERM_OFFSET_TOP;

export const SET_TOC_SUCCESS = "SET_TOC_SUCCESS";
export type SET_TOC_SUCCESS = typeof SET_TOC_SUCCESS;

export const SET_ANALYSIS = "SET_ANALYSIS";
export type SET_ANALYSIS = typeof SET_ANALYSIS;

export const ADD_RECOMMENDATIONS = "ADD_RECOMMENDATIONS";
export type ADD_RECOMMENDATIONS = typeof ADD_RECOMMENDATIONS;

export const MARK_RECOMMENDATION_AS_INSERTED = "MARK_RECOMMENDATION_AS_INSERTED";
export type MARK_RECOMMENDATION_AS_INSERTED = typeof MARK_RECOMMENDATION_AS_INSERTED;

// Fonts
export const INCREASE_FONT_SIZE = "INCREASE_FONT_SIZE";
export type INCREASE_FONT_SIZE = typeof INCREASE_FONT_SIZE;

export const DECREASE_FONT_SIZE = "DECREASE_FONT_SIZE";
export type DECREASE_FONT_SIZE = typeof DECREASE_FONT_SIZE;

export const CHANAGE_FONT_FAMILY = "CHANGE_FONT_FAMILY";
export type CHANAGE_FONT_FAMILY = typeof CHANAGE_FONT_FAMILY;

export const RESET_TO_DEFAULT = "RESET_TO_DEFAULT";
export type RESET_TO_DEFAULT = typeof RESET_TO_DEFAULT;

// User
export const SET_USER = "SET_USER";
export type SET_USER = typeof SET_USER;

export const SET_LICENSE_TYPE = "SET_LICENSE_TYPE";
export type SET_LICENSE_TYPE = typeof SET_LICENSE_TYPE;

export const SET_ADMIN = "SET_ADMIN";
export type SET_ADMIN = typeof SET_ADMIN;

export const SET_SUBSCRIPTION_OWNER = "SET_SUBSCRIPTION_OWNER";
export type SET_SUBSCRIPTION_OWNER = typeof SET_SUBSCRIPTION_OWNER;

export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export type SET_ACCESS_TOKEN = typeof SET_ACCESS_TOKEN;

export const SET_ANALYSIS_ENGINE = "SET_ANALYSIS_ENGINE";
export type SET_ANALYSIS_ENGINE = typeof SET_ANALYSIS_ENGINE;

// Regex
export const ENABLE_REGEX_EXPRESSION = "ENABLE_REGEX_EXPRESSION";
export type ENABLE_REGEX_EXPRESSION = typeof ENABLE_REGEX_EXPRESSION;

export const DISABLE_REGEX_EXPRESSION = "DISABLE_REGEX_EXPRESSION";
export type DISABLE_REGEX_EXPRESSION = typeof DISABLE_REGEX_EXPRESSION;

// Applcation
export const SET_SCROLL_TERM = "SET_SCROLL_TERM";
export type SET_SCROLL_TERM = typeof SET_SCROLL_TERM;

export const ADD_SECONDARY_SCROLL_TERM = "ADD_SECONDARY_SCROLL_TERM";
export type ADD_SECONDARY_SCROLL_TERM = typeof ADD_SECONDARY_SCROLL_TERM;

export const REMOVE_SECONDARY_SCROLL_TERM = "REMOVE_SECONDARY_SCROLL_TERM";
export type REMOVE_SECONDARY_SCROLL_TERM = typeof REMOVE_SECONDARY_SCROLL_TERM;

export const CLEAR_SECONDARY_SCROLL_TERM = "CLEAR_SECONDARY_SCROLL_TERM";
export type CLEAR_SECONDARY_SCROLL_TERM = typeof CLEAR_SECONDARY_SCROLL_TERM;

export const SET_IS_GRAPH_DIALOG = "SET_IS_GRAPH_DIALOG";
export type SET_IS_GRAPH_DIALOG = typeof SET_IS_GRAPH_DIALOG;

// AI Settings
export const SET_AI_PROVIDER = "SET_AI_PROVIDER";
export type SET_AI_PROVIDER = typeof SET_AI_PROVIDER;

export const SET_AI_MODEL = "SET_AI_MODEL";
export type SET_AI_MODEL = typeof SET_AI_MODEL;

export const SET_AI_API_KEY = "SET_AI_API_KEY";
export type SET_AI_API_KEY = typeof SET_AI_API_KEY;

export const SET_AI_SETTINGS = "SET_AI_SETTINGS";
export type SET_AI_SETTINGS = typeof SET_AI_SETTINGS;
