import * as React from "react";
import { connect } from "react-redux";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { RouteComponentProps } from "react-router-dom";
import { ITerm } from "../../interfaces/ITermsInterface";
import {
  setCurrentTermOffsetTop,
  setCurrentTermOffsetTopType,
} from "../../actions/terms";
import TermsLoading from "../../components/TermsLoading";
import { Section, Container } from "../../components/ui/Grid";
import { TermsList } from "./styled";
import Prompt from "../ai/prompt";
import AiSettings from "../ai/settings";
import { useEffect, useRef, useState } from "react";
import { getUser, getUserAiSetting } from "../../../utilities/server";
import { setScrollTerm } from "../../actions/application";
import { ToastContainer, toast } from "react-toastify";
import {
  getDocumentSegmentedData,
  getDocumentSpecificNamespace,
  reIngestDoc,
} from "../../../utilities/utils";
import { RootState } from "../../reducers";
import { useUser } from "../../hooks/useUser";

interface IHomeScreenProps extends RouteComponentProps<any> {
  currentTermOffsetTop: number;
  setCurrentTermOffsetTop: setCurrentTermOffsetTopType;
  analysis: any;
  setScrollTerm: any;
}

const HomeScreen: React.FC<IHomeScreenProps> = (props) => {
  const { analysis } = props;

  const container = useRef<HTMLDivElement>(null);

  const getClausesByType = (type: string) => analysis.clauses.filter((r) => r.clauseType === type);

  const terms = getClausesByType("TERM");
  const sections = getClausesByType("SECTION"); 
  const annexes = getClausesByType("ANNEX");
  const schedules = getClausesByType("SCHEDULE");
  const articles = getClausesByType("ARTICLE");
  const exhibits = getClausesByType("EXHIBIT");

  const goToTerm = (
    _e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    term: ITerm
  ) => {
    props.history.push(`/term/${term.id}`);
  };

  const compareSectionNumbers = (a: string, b: string) => {
    const splitA = a.split(".");
    const splitB = b.split(".");
    if (splitA.length === 1 || splitB.length === 1 || splitA[0] !== splitB[0]) {
      return parseFloat(splitA[0]) > parseFloat(splitB[0]) ? 1 : -1;
    } else {
      return parseFloat(splitA[1]) > parseFloat(splitB[1]) ? 1 : -1;
    }
  };

  if (terms === null) {
    return <TermsLoading text="Analyzing Document" />;
  }

  return (
    <Section ref={container} style={{ overflowY: "hidden" }}>
      <Pivot
        overflowBehavior="menu"
        overflowAriaLabel="more items"
        linkSize="normal"
        onLinkClick={(item) => {
          props.history.push(`/`, {
            currentSection: item.props.itemKey,
          });
        }}
      >
        <PivotItem
          style={{
            height: "calc(100vh - 25vh)",
            overflow: "auto",
          }}
          headerText="AI Vision"
          itemKey="ai-vision"
          headerButtonProps={{
            disabled: false,
            style: {
              color: "black",
            },
            "data-order": 1,
            "data-title": "AI Vision",
          }}
        >
          <Prompt />
        </PivotItem>
        <PivotItem
          style={{
            height: "calc(100vh - 30vh)",
            overflow: "auto",
          }}
          headerText="AI Settings"
          itemKey="ai-setting"
          headerButtonProps={{
            "data-order": 0,
            "data-title": "AI Settings",
          }}
        >
          <AiSettings
          />
        </PivotItem>

        <PivotItem
          style={{
            height: "calc(100vh - 30vh)",
            overflow: "auto",
          }}
          headerText="Terms"
          itemKey="terms"
          headerButtonProps={{
            "data-order": 2,
            "data-title": "Terms",
          }}
        >
          <Container>
            <TermsList>
              {terms
                .sort((a, b) =>
                  a.word > b.word ? 1 : b.word > a.word ? -1 : 0
                )
                .map((term, index) => (
                  <li key={index} onClick={(e) => goToTerm(e, term)}>
                    {term.word}
                  </li>
                ))}

              {terms.length === 0 && <span>No Terms Found</span>}
            </TermsList>
          </Container>
        </PivotItem>
        <PivotItem
          style={{
            height: "calc(100vh - 30vh)",
            overflow: "auto",
          }}
          headerText="Sections"
          itemKey="sections"
          headerButtonProps={{
            "data-order": 3,
            "data-title": "Sections",
          }}
        >
          <Container>
            <TermsList>
              {sections.length === 0 && <span>No Sections Found</span>}
              {sections
                .sort((a, b) => {
                  if (
                    a.title === a.definedDefinition[0] &&
                    a.definedDefinition.length === 1 &&
                    b.title !== b.definedDefinition[0]
                  ) {
                    return 1;
                  }

                  if (
                    a.title !== a.definedDefinition[0] &&
                    b.title === b.definedDefinition[0] &&
                    b.definedDefinition.length === 1
                  ) {
                    return -1;
                  }

                  if (a.word === b.word) return a.title > b.title ? 1 : -1;

                  if (isNaN(Number(a.word)) || isNaN(Number(b.word))) {
                    const matchNumberRegex = /[0-9][0-9,\.]*/;

                    const matchA = a.word.match(matchNumberRegex)
                      ? a.word.match(matchNumberRegex)[0]
                      : null;
                    const matchB = b.word.match(matchNumberRegex)
                      ? b.word.match(matchNumberRegex)[0]
                      : null;

                    if (!matchA || !matchB) {
                      return a.word > b.word ? 1 : -1;
                    } else {
                      return compareSectionNumbers(matchA, matchB);
                    }
                  }

                  return compareSectionNumbers(a.word, b.word);
                })
                .map((section, index) => (
                  <li key={index} onClick={(e) => goToTerm(e, section)}>
                    {section.title}
                  </li>
                ))}
            </TermsList>
          </Container>
        </PivotItem>
        {/* Repeat similar structure for Articles, Exhibits, Schedules, Annexes */}
        <PivotItem
          style={{
            height: "calc(100vh - 30vh)",
            overflow: "auto",
          }}
          headerText="Articles"
          itemKey="articles"
          headerButtonProps={{
            "data-order": 4,
            "data-title": "Articles",
          }}
        >
          <Container>
            <TermsList >
              {articles.length === 0 && <span>No Articles Found</span>}
              {articles
                .sort((a, b) =>
                  a.word > b.word ? 1 : b.word > a.word ? -1 : 0
                )
                .map((article, index) => (
                  <li key={index} onClick={(e) => goToTerm(e, article)}>
                    {article.title}
                  </li>
                ))}
            </TermsList>
          </Container>
        </PivotItem>
        {/* Exhibits */}
        <PivotItem
          style={{
            height: "calc(100vh - 30vh)",
            overflow: "auto",
          }}
          headerText="Exhibits"
          itemKey="exhibits"
          headerButtonProps={{
            "data-order": 5,
            "data-title": "Exhibits",
          }}
        >
          <Container>
            <TermsList>
              {exhibits.length === 0 && <span>No Exhibits Found</span>}
              {exhibits
                .sort((a, b) =>
                  a.word > b.word ? 1 : b.word > a.word ? -1 : 0
                )
                .map((exhibit, index) => (
                  <li key={index} onClick={(e) => goToTerm(e, exhibit)}>
                    {exhibit.title}
                  </li>
                ))}
            </TermsList>
          </Container>
        </PivotItem>
        {/* Schedules */}
        <PivotItem
          style={{
            height: "calc(100vh - 30vh)",
            overflow: "auto",
          }}
          headerText="Schedules"
          itemKey="schedules"
          headerButtonProps={{
            "data-order": 6,
            "data-title": "Schedules",
          }}
        >
          <Container>
            <TermsList>
              {schedules.length === 0 && <span>No Schedules Found</span>}
              {schedules
                .sort((a, b) =>
                  a.word > b.word ? 1 : b.word > a.word ? -1 : 0
                )
                .map((schedule, index) => (
                  <li key={index} onClick={(e) => goToTerm(e, schedule)}>
                    {schedule.title}
                  </li>
                ))}
            </TermsList>
          </Container>
        </PivotItem>
        {/* Annexes */}
        <PivotItem
          style={{
            height: "calc(100vh - 30vh)",
            overflow: "auto",
          }}
          headerText="Annexes"
          itemKey="annexes"
          headerButtonProps={{
            "data-order": 7,
            "data-title": "Annexes",
          }}
        >
          <Container>
            <TermsList>
              {annexes.length === 0 && <span>No Annexes Found</span>}
              {annexes
                .sort((a, b) =>
                  a.word > b.word ? 1 : b.word > a.word ? -1 : 0
                )
                .map((annex, index) => (
                  <li key={index} onClick={(e) => goToTerm(e, annex)}>
                    {annex.title}
                  </li>
                ))}
            </TermsList>
          </Container>
        </PivotItem>
      </Pivot>
      <ToastContainer />
    </Section>
  );
};

const mapStateToProps = (state: RootState) => ({
  loadingStatus: state.terms.loadingStatus,
  currentTermOffsetTop: state.terms.currentTermOffsetTop,
  user: state.user,
  scrollTerm: state.application.scrollTerm,
  analysis: state.terms.analysis,
});

const mapDispatchToProps = (dispatch) => ({
  setScrollTerm: (scrollTerm: ITerm) => dispatch(setScrollTerm(scrollTerm)),
  setCurrentTermOffsetTop: (offsetTop) =>
    dispatch(setCurrentTermOffsetTop(offsetTop)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
