import React, { useEffect, useState } from "react";
import patienceDiff from "../../../../utilities/patienceDiff";
import { RedText, GreenText, Text } from "./styled";
import { textDiff } from "../../../../utilities/textDiff";

type IProps = {
  originalText: string;
  suggestedText: string;
};
const Redline = ({ originalText, suggestedText }: IProps) => {
  const [model, setModel] = useState([]);


  useEffect(() => {

    /*const results = patienceDiff(
      originalText.split(" "),
      suggestedText.replace(/\r/g, '').split(" ")
    );*/

   const result2 = textDiff(originalText, suggestedText);

  setModel(result2);
/*
    let mode = "";
    let workingObj: { words: string[]; mode: string };
    let newModal = [];

    for (var i = 0; i < results.lines.length; i++) {
      let result = results.lines[i];
      let nMode;

      if (result.aIndex < 0 && result.bIndex >= 0) {
        nMode = "new";
      } else if (result.aIndex >= 0 && result.bIndex >= 0) {
        nMode = "both";
      } else {
        nMode = "old";
      }

      if (mode != nMode) {
        workingObj = {
          words: [result.line],
          mode: nMode,
        };

        newModal.push(workingObj);
        mode = nMode;
      } else {
        workingObj.words.push(result.line);
      }
    }

    setModel(newModal);*/
  }, []);

  if (!model.length) return <p>no resultes</p>;

  return (
    <p>
      {model.filter(item => item.value.trim().length >= 1).map((item, i) => {
        if(item.added) {
          return <GreenText key={i}>{item.value + " "}</GreenText>;
        } else if(item.removed) {
          return <RedText key={i}>{item.value + " "}</RedText>;
        } else {
          return <Text key={i}>{item.value + " "}</Text>;
        }
      })}
    </p>
  );
};

export default Redline;
