import React from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import PropTypes from "prop-types";
import Clause from "../src/components/Clause";
import {
  addSecondaryScrollTerm,
  removeSecondaryScrollTerm,
} from "../src/actions/application";

const TermModal = (props) => {
  const navigateToTerm = (word) => {
    const clauseToNavTo = props.clauses.filter((r) => r.word === word)[0];
    props.setCurrentTerm(clauseToNavTo.id);
  };

  return (
    <Modal
      style={{
        content: {
          padding: "0px",
          width: "85%",
        },
      }}
      isOpen
      onRequestClose={() => props.setCurrentTerm(null)}
    >
      <Clause
        navigateToClause={navigateToTerm}
        clause={props.clauses.filter((r) => r.id === props.id)[0]}
        clauses={props.clauses}
        secondaryScrollTerms={props.secondaryScrollTerms}
        addSecondaryScrollTerm={props.addSecondaryScrollTerm}
        removeSecondaryScrollTerm={props.removeSecondaryScrollTerm}
      ></Clause>
    </Modal>
  );
};

TermModal.propTypes = {
  id: PropTypes.string,
  clauses: PropTypes.array,
};

TermModal.defaultProps = {};

const mapStateToProps = (state) => ({
  clauses: state.terms.analysis.clauses,
  secondaryScrollTerms: state.application.secondaryScrollTerms,
});

const mapDispatchToProps = (dispatch) => ({
  addSecondaryScrollTerm: (scrollTerm: string) =>
    dispatch(addSecondaryScrollTerm(scrollTerm)),
  removeSecondaryScrollTerm: (scrollTerm: string) =>
    dispatch(removeSecondaryScrollTerm(scrollTerm)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermModal);
