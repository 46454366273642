import { modelType } from "./types";

export const SELECTED_TEXT_CHARACTER_LIMIT = 10000;


export const openAiModels: modelType[] = [
  {
    name: "GPT-4o",
    value: "gpt-4o",
    modelId: 1,
  },
  {
    name: "GPT-4o mini",
    value: "gpt-4o-mini",
    modelId: 2,
  },
];

export const togetherModels: modelType[] = [
  {
    name: "Llama 3.1 8B Instruct Turbo",
    value: "meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo",
    modelId: 7,
  },
  {
    name: "Llama 3.1 70B Instruct Turbo",
    value: "meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo",
    modelId: 8,
  },
];

export const anthropicModels: modelType[] = [
  {
    name: "Claude 3 Opus",
    value: "claude-3-opus-20240229",
    modelId: 5,
  },
  {
    name: "Claude 3 Sonnet",
    value: "claude-3-sonnet-20240229",
    modelId: 6,
  },
];

export const mistralModels: modelType[] = [
  {
    name: "Mistral Large",
    value: "mistral-large-latest-2407",
    modelId: 3,
  },
  {
    name: "Pixtral 12B",
    value: "pixtral-12b-2409",
    modelId: 4,
  },
];

export const providers = [
  {
    name: "OpenAI",
    value: "openai",
    providerId: 1,
    models: openAiModels,
  },
  {
    name: "Anthropic",
    value: "anthropic",
    providerId: 2,
    models: anthropicModels,
  },
  {
    name: "Together AI",
    value: "togetherai",
    providerId: 3,
    models: togetherModels,
  },  
  {
    name: "Mistral AI",
    value: "mistralai",
    providerId: 4,
    models: mistralModels,
  },
];

export const getProviderById = (providerId: number) => {
  return providers.find((provider) => provider.providerId === providerId);
};

export const getProviderByNameValue = (nameValue: string) => {
  return providers.find((provider) => provider.value === nameValue);
};

export const getProviderByName = (name: string) => {
  return providers.find((provider) => provider.name === name);
};

export const getModelId = (model: string) => {
  return providers.find((provider) => provider.models.find((m) => m.value === model))?.models.find((m) => m.value === model)?.modelId;
};
