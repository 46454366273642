import { getFile } from "../src/actions/prompt";
import { environment_conf } from "./environment";
import axios from "axios";
import analyzeDocument from "../src/anaylsis/regex/analyzeDocument"
import { toast } from "react-toastify";
import crypto from "crypto"

type Term = {
  word: string;
  definedDefinition?: { html: string }[];
  definition?: { html: string }[];
};

type Section = {
  word: string;
  definedDefinition: { text: string }[];
};

type Exhibit = Section;
type Schedule = Section;
type Annexe = Section;
type Article = Section;

type Content = {
  terms?: Term[];
  sections?: Section[];
  exhibits?: Exhibit[];
  schedules?: Schedule[];
  annexes?: Annexe[];
  articles?: Article[];
};

const safeMap = <T, R>(arr: T[] | undefined, callback: (item: T) => R): R[] => {
  if (!arr) return [];
  return arr.map(item => {
    try {
      return callback(item);
    } catch (error) {
      console.error('Error processing item:', item, error);
      return null;
    }
  }).filter(item => item !== null) as R[];
};

const processItem = (item: { word: string, definedDefinition: { html?: string, text?: string }[], sections?: string[] }, isHTML: boolean): { title: string, content: string } => {
  const title = `${item.word}\n\n`;

  const definition = item?.definedDefinition?.map(paragraph => isHTML ? paragraph.html : paragraph.text).join('\n') ?? '';
  const sections = item?.sections?.join('\n') ?? '';
  return {
    title: item.word,
    content: title + definition + '\n\n' + sections
  };
};

export const getDocumentSegmentedData = async () => {
  try {
    const fileContent = await getFile();
    const content: Content = analyzeDocument(fileContent);

    const terms = safeMap(content.terms, (term) => {
      const title = `${term.word}\n\n`;
      const definedDefinition = term?.definedDefinition?.map((value) => value.html).join('\n') ?? '';
      const inlineDefinition = term?.definition?.map((r) => r.html).join('\n') ?? '';
      return {  
        title: term.word,
        content: title + definedDefinition + '\n\n' + inlineDefinition
      };
    });


    const sections =  safeMap(content.sections, section => processItem(section, false));
    const exhibits =  safeMap(content.exhibits, exhibit => processItem(exhibit, false));
    const schedules =  safeMap(content.schedules, schedule => processItem(schedule, false));
    const annexes =  safeMap(content.annexes, annexe => processItem(annexe, false));
    const articles =  safeMap(content.articles, article => processItem(article, false));


    const data = {
      terms,
      sections,
      exhibits,
      schedules,
      annexes,
      articles,
    };

    return data;
  } catch (error) {
    console.error('Error fetching and processing document data:', error);
    throw new Error('Failed to get document segmented data');
  }
};

export function bytesToBase64(bytes: Uint8Array): string {
  const binString = Array.from(bytes, (byte) =>
    String.fromCodePoint(byte)
  ).join("");
  return btoa(binString);
}

export const getTermsWords = async (): Promise<string[]> => {
  const fileContent = await getFile();

  const content = analyzeDocument(fileContent);

  return content.terms.flatMap((term) => {
    const words = [term.word];
    if (term.alternativeTerms) {
      words.push(...term.alternativeTerms.map(alias => alias.word));
    }
    return words;
  });
}

export const getDocumentSpecificNamespace = async (userId, data) => {
  var hash = crypto.createHash('md5').update(JSON.stringify(data)).digest('hex');
  return `${userId}-${hash}`
}


export const ingestDoc = async (userId: string, apiKey: string) => {
  const data = await getDocumentSegmentedData()

  // Create unique namespace for this document
  const namespace = await getDocumentSpecificNamespace(userId, data)

  const body = {
    "data": data,
    "namespace": namespace,
    "apiKey": apiKey,
  }
console.log('body: ', data);
  return axios.post(`${environment_conf.base_llm_domain}/ingest`, body);
};

export const reIngestDoc = async (data: any, namespace: string, apiKey: string) => {
  const body = {
    "data": data,
    "namespace": namespace,
    "apiKey": apiKey,
  }

  console.log("REINGESTING DOC", body);

  return axios.post(`${environment_conf.base_llm_domain}/ingest`, body);
};

