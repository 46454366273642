export enum LICENSE_TYPES {
  BASIC = "BASIC",
  PREMIUM = "PREMIUM",
  NONE = "NONE",
  ENTERPRISE = "ENTERPRISE",
}

export enum SUBSCRIPTION_LEVEL {
  BASIC = "BASIC",
  PREMIUM = "PREMIUM",
}

export enum ANALYSIS_ENGINE {
  AI = "AI",
  REGEX = "REGEX",
}

export interface IUser {
  id: string;
  name: string;
  email: string;
  loggedIn: boolean;
  licenseType: LICENSE_TYPES;
  admin: boolean;
  subscriptionOwner: boolean;
  analysisEngine: ANALYSIS_ENGINE;
  masterUserId: string;
}

export type User = {
  id: string;
  accessToken: string;
  admin: boolean;
  aiApiKey: string;
  aiModel: string;
  aiOrganisationId: string;
  aiProvider: string;
  authStatus: string;
  email: string;
  licenseType: string;
  loggedIn: boolean;
  name: string;
  provider: string;
  subscriptionOwner: boolean;
  masterUserId: string;
};
