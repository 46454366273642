import Diff from 'diff';

export const textDiff = (oldText: string, newText: string) => {
    const oldTextArray = oldText;
    const newTextArray = newText;

  const results = Diff.diffWordsWithSpace(oldTextArray, newTextArray, {
    stripTrailingCr:true,
    ignoreWhitespace:true
  });

    return results;
};